.RecklessNeue {
  font-family: 'Reckless Neue';
}

.LotaGrotesque {
  font-family: 'Lota Grotesque';
}

.introjs-button{
  background-color: #2563EB !important;
  color: white !important;
  text-shadow: none !important;
  border: none !important; 
}