@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F8F9;
  overflow-y: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a{
  text-decoration: none !important;
}

a u{
  text-decoration: none !important;
}

.ql-tooltip{
  display: none;
}

.ql-container{
  margin-top: 10px !important;
}

.swal2-html-container a{
  color: rgb(37 99 235);
  text-decoration: underline !important;
}

.ql-toolbar{
  margin-bottom: 30px !important;
}

.quill h2{
  font-size: 18px !important;
  font-weight: bold;
}

.react-datepicker-wrapper{
  width: 100%;
  height: 100%;
  border-radius: 6px !important;
}

.react-datepicker__input-container{
  width: 100%;
  height: 100%;
  border-radius: 6px !important;
}

.react-datepicker__input-container input{
  width: 100%;
  height: 100%;
  color: black !important;
  padding-left: 20px;
  border-radius: 6px !important;
}

canvas{
  width: 100% !important;
  height: 100% !important;
}

.pg-photo-container{
  width: 100% !important;
  height: 100% !important;
}

img{
  width: 100% !important;
  height: 100% !important;
}